







import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import { mapActions } from "vuex";
import { GOOGLE_AUTH, LINKEDIN_AUTH } from "@/store/modules/auth/constants";

export default Vue.extend({
  name: "SocialAuthenticationBtn",
  props: {
    btn_text: {
      type: String as PropType<TranslateResult>,
      required: true
    },
    btn_icon: {
      type: String,
      required: true
    },
    provider: {
      type: Number,
      required: true
    }
  },
  methods: {
    ...mapActions("auth", {
      google_auth: GOOGLE_AUTH,
      linkedin_auth: LINKEDIN_AUTH
    }),
    // Todo: Implement social login
    async social_login() {
      if (this.provider === 1) {
        await this.google_auth();
      } else if (this.provider === 2) {
        await this.linkedin_auth();
      } else if (this.provider === 3) {
        await this.google_auth();
      } else if (this.provider === 4) {
        await this.linkedin_auth();
      }
    }
  }
});
